// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xmPvF4jdMtgPpKk9U1PE {\n  border-color: #ff0000 !important;\n  color: #c2cfff !important;\n}\n\n.IDT5sZnW4HgVDOZ8kjRc {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #666666 !important;\n}\n\n.ltd1PX2onb_70YgcXu4Q {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n}\n\n.HjJgao_0QOp494iypF8v:focus {\n  border: none;\n  box-shadow: none;\n  outline: 0;\n}", "",{"version":3,"sources":["webpack://./src/core-components/CustomSelect/CustomSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,yBAAA;AACF;;AAEA;EACE,gCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,gCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;EACA,UAAA;AACF","sourcesContent":[".error {\n  border-color: #ff0000 !important;\n  color: #c2cfff !important;\n}\n\n.normal {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #666666 !important;\n}\n\n.placeholder {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n}\n\n.customSelectSearch:focus {\n  border: none;\n  box-shadow: none;\n  outline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "xmPvF4jdMtgPpKk9U1PE",
	"normal": "IDT5sZnW4HgVDOZ8kjRc",
	"placeholder": "ltd1PX2onb_70YgcXu4Q",
	"customSelectSearch": "HjJgao_0QOp494iypF8v"
};
export default ___CSS_LOADER_EXPORT___;
