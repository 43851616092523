import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import AppConstants from '../../core-components/AppConstants';
import AppHelper from '../../core-components/AppHelper';
import googleEnviroment from './../../config/googleauth.json';

import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormInput from '../../core-components/FormInput';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from '../../images/gIcon.svg';
import { errToastMessage } from '../../utils/Utlities';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
    color: theme.palette.text.secondary
  },
  activeBtn: {
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  errorMsg: {
    fontSize: '0.75rem',
    color: '#f44336',
    fontWeight: '400',
    lineHeight: '1.66',
    margin: 0,
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(3)
  },
  googleLoginBtn: {
    width: '240px',
    height: '40px',
    backgroundColor: '#1a73e8',
    color: '#fff',
    cursor: 'pointer',
    borderRadius: '3px',
    fontFamily: '"Google Sans",arial,sans-serif',
    fontWeight: 500,
    lineHeight: '36px',
    fontSize: '14px',
    padding: '2px',
    display: 'flex'
  },
  googleIcon: {
    width: '36px',
    height: '36px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px 0 0 3px'
  },
  gLoginText: {
    flex: 1,
    textAlign: 'center',
    paddingRight: '8px',
    letterSpacing: ' 0.4px'
  },
  required: {
    color: '#db3131'
  },
  customFormLabel: {
    marginBottom: 10,
    marginTop: 10
  },
  customImgWidth: {
    maxWidth: 180
  },
  eye: {
    cursor: 'pointer'
  },
  googleBtn: {
    marginTop: '15vh',
    marginLeft: '30vh'
  },
  twoWayLogin: {
    marginTop: '1rem',
    width: '50%'
  }
}));

export default (props) => {
  const { errors, setFieldValue, isSubmitting, values } = props;
  const classes = useStyles();
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);

  const googleLoginGetEnv =
    ['local', 'dev', 'dev-2', 'dev-3'].includes(AppHelper.getEnv()) || false;

  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setPasswordIsMasked(true);
    props.handleSubmit();
  };

  const isValidForm = !isSubmitting && isEmpty(errors);

  // function to handle when googlelogin failed
  const handleFailure = (e) => {
    errToastMessage(e);
  };

  const redirectToGoogle = () => {
    let lastRedirectPathname = 'dashboard';
    const stateFrom = props.location.state?.from?.pathname;
    if (localStorage.getItem('last-redirect-pathname')) {
      lastRedirectPathname = `${localStorage.getItem('last-redirect-pathname')}`;
    } else if (stateFrom && stateFrom !== '/logout') {
      lastRedirectPathname = `${stateFrom.startsWith('/') ? stateFrom.substring(1) : stateFrom}${
        props.location.state?.from?.search
      }`;
    }
    localStorage.setItem('last-redirect-pathname', lastRedirectPathname);
    googleRedirectionLogin();
  };
  const googleRedirectionLogin = useGoogleLogin({
    ux_mode: 'redirect',
    flow: 'auth-code',
    redirect_uri: `${AppConstants.baseURL}google-redirect`,
    onError: (error) => handleFailure(error)
  });

  return (
    <Box data-testid='login-box' mt={3} mb={3}>
      <Grid container direction='row'>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <form onSubmit={handleFormSubmit}>
            <Typography variant={'h6'}>Login</Typography>
            {googleLoginGetEnv && (
              <div>
                <Typography>Please fill out the following fields to login :</Typography>
                <Paper variant='outlined' elevation={5} className={classes.paper}>
                  <Field
                    data-testid='email'
                    type='text'
                    name='email'
                    label='Email'
                    component={FormInput}
                  />
                  <Field
                    type={passwordIsMasked ? 'password' : 'text'}
                    data-testid='password'
                    name='password'
                    label='Password'
                    component={FormInput}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton onClick={() => togglePasswordMask()} size='small'>
                          {passwordIsMasked ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.rememberMe}
                        onChange={(e) => {
                          setFieldValue('rememberMe', e.target.checked);
                        }}
                        name='rememberMe'
                        color='primary'
                      />
                    }
                    label='Remember Me'
                  />
                </Paper>
                <Button
                  variant='contained'
                  type='submit'
                  data-testid='login-btn'
                  className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                  disabled={!isValidForm}
                >
                  Login
                </Button>
              </div>
            )}
            {googleLoginGetEnv && (
              <div>
                <Typography variant={'h6'} style={{ marginTop: '1rem' }}>
                  OR
                </Typography>
              </div>
            )}
            <div className={googleLoginGetEnv ? classes.twoWayLogin : classes.googleBtn}>
              <div className={classes.googleLoginBtn} onClick={() => redirectToGoogle()}>
                <div className={classes.googleIcon}>
                  <GoogleIcon style={{ width: '18px' }} />
                </div>{' '}
                <span className={classes.gLoginText}>Sign in with Google</span>
              </div>
            </div>
          </form>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
      </Grid>
    </Box>
  );
};
