// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pX3wFIlg62R3QffD4B5z {\n  background: #ffffff;\n  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n  border-radius: 4px;\n  padding: 20px 24px;\n  margin-bottom: 24px;\n  font-family: Poppins, sans-serif;\n}\n.pX3wFIlg62R3QffD4B5z .zZ_I3NvmHTbNhTDFUPsQ {\n  display: flex;\n  align-items: center;\n  justify-content: end;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/frontend-common/AlumniVerification/AddAlumni/AddAlumni.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gDAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gCAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;AAEJ","sourcesContent":[".addAlumniContainerWrapper {\n  background: #ffffff;\n  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n  border-radius: 4px;\n  padding: 20px 24px;\n  margin-bottom: 24px;\n  font-family: Poppins, sans-serif;\n  .addAlumniBox {\n    display: flex;\n    align-items: center;\n    justify-content: end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addAlumniContainerWrapper": "pX3wFIlg62R3QffD4B5z",
	"addAlumniBox": "zZ_I3NvmHTbNhTDFUPsQ"
};
export default ___CSS_LOADER_EXPORT___;
