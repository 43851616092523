// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eV8nn2NOUfQ3WITwP4i6 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n}\n\n.Q4hmi2vRbV9SLxOEJSoP {\n  font-size: 0.8rem;\n  color: rgb(113, 113, 113);\n}\n\n.vax9VwAMFCs3A0e_Gho_ {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 14px;\n}\n\n.LAv7nG0_No1J2Tz7PgcY {\n  font-weight: 600;\n}\n\n.uNFtZJzjRjmgfKaEVhQM {\n  color: orange;\n}\n\n.izwdKVzqvgAOCyccPGnq {\n  color: green;\n}\n\ninput:disabled {\n  cursor: not-allowed;\n}\n\n.h0IHaygTr6DeK50DokTx {\n  font-size: 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/Credits/Prepaid/ViewUpdateLogsModal/ViewUpdateLogsModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AACF;;AACA;EACE,iBAAA;EACA,yBAAA;AAEF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAGF;;AADA;EACE,gBAAA;AAIF;;AAFA;EACE,aAAA;AAKF;;AAHA;EACE,YAAA;AAMF;;AAJA;EACE,mBAAA;AAOF;;AALA;EACE,eAAA;AAQF","sourcesContent":[".dialogTitle {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n}\n.transactionDetails {\n  font-size: 0.8rem;\n  color: rgb(113, 113, 113);\n}\n.finance {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 14px;\n}\n.bold {\n  font-weight: 600;\n}\n.orange {\n  color: orange;\n}\n.green {\n  color: green;\n}\ninput:disabled {\n  cursor: not-allowed;\n}\n.fieldMissing {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogTitle": "eV8nn2NOUfQ3WITwP4i6",
	"transactionDetails": "Q4hmi2vRbV9SLxOEJSoP",
	"finance": "vax9VwAMFCs3A0e_Gho_",
	"bold": "LAv7nG0_No1J2Tz7PgcY",
	"orange": "uNFtZJzjRjmgfKaEVhQM",
	"green": "izwdKVzqvgAOCyccPGnq",
	"fieldMissing": "h0IHaygTr6DeK50DokTx"
};
export default ___CSS_LOADER_EXPORT___;
