// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wJZBWgaoeS4m5Fnrcw7X .eRD6DEosNeCWQGpVDRlp {\n  font-size: 16px;\n}\n.wJZBWgaoeS4m5Fnrcw7X .nCu6rxbeQDDXz6h9nffF {\n  font-size: 16px;\n  padding: 20px 20px 20px 0px;\n}\n.wJZBWgaoeS4m5Fnrcw7X .nCu6rxbeQDDXz6h9nffF ._R4kRv1xeQTxzkBlglVo {\n  padding: 0px 9px;\n  color: #2755fe;\n}\n.wJZBWgaoeS4m5Fnrcw7X .nCu6rxbeQDDXz6h9nffF ._R4kRv1xeQTxzkBlglVo:hover {\n  padding: 0px 9px;\n  color: #2755fe;\n  cursor: pointer;\n}\n\n.orh4ZYveYwpc31MsgEYw {\n  color: #fff;\n  margin: 10px 10px 6px 0px;\n  width: 136px;\n  height: 48px;\n  background-color: #007bff;\n  border-color: #007bff;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/frontend-common/SecuritySettings/DomainLockModal/DomainLockModal.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,eAAA;EACA,2BAAA;AAAJ;AACI;EACE,gBAAA;EACA,cAAA;AACN;AACI;EACE,gBAAA;EACA,cAAA;EACA,eAAA;AACN;;AAIA;EACE,WAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;AADF","sourcesContent":[".bodyDiv {\n  .heading1 {\n    font-size: 16px;\n  }\n  .heading2 {\n    font-size: 16px;\n    padding: 20px 20px 20px 0px;\n    .aTag {\n      padding: 0px 9px;\n      color: #2755fe;\n    }\n    .aTag:hover {\n      padding: 0px 9px;\n      color: #2755fe;\n      cursor: pointer;\n    }\n  }\n}\n\n.btn {\n  color: #fff;\n  margin: 10px 10px 6px 0px;\n  width: 136px;\n  height: 48px;\n  background-color: #007bff;\n  border-color: #007bff;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyDiv": "wJZBWgaoeS4m5Fnrcw7X",
	"heading1": "eRD6DEosNeCWQGpVDRlp",
	"heading2": "nCu6rxbeQDDXz6h9nffF",
	"aTag": "_R4kRv1xeQTxzkBlglVo",
	"btn": "orh4ZYveYwpc31MsgEYw"
};
export default ___CSS_LOADER_EXPORT___;
