// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xvb5QrR2i772RkJcNs0Y .Nmr24gb1x5k06oeQT_I1 {\n  width: 100%;\n  background: #2755fe;\n  border-radius: 2px;\n  font-weight: normal;\n  font-size: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 8.5rem;\n}\n.Xvb5QrR2i772RkJcNs0Y .kS_F4FGW_aBDbBoy7wYk {\n  font-family: roboto;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/frontend-common/core-components/Razorpay/Razorpay.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".addBtnContainer {\n  .payBtn {\n    width: 100%;\n    background: #2755fe;\n    border-radius: 2px;\n    font-weight: normal;\n    font-size: 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 8.5rem;\n  }\n\n  .rupee {\n    font-family: roboto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtnContainer": "Xvb5QrR2i772RkJcNs0Y",
	"payBtn": "Nmr24gb1x5k06oeQT_I1",
	"rupee": "kS_F4FGW_aBDbBoy7wYk"
};
export default ___CSS_LOADER_EXPORT___;
