// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QQY0qP3GUOmu3vytYDqo {\n  font-size: 18px;\n  color: #4c4c4c;\n  margin-bottom: 28px;\n  display: flex;\n}\n\n.SgnAk2auFDKi05P8P1jp {\n  margin-right: 24px;\n  background-color: #fff;\n  padding: 0 12px 0 4px;\n  font-size: 14px;\n  color: #4c4c4c;\n}\n.SgnAk2auFDKi05P8P1jp label {\n  color: #4c4c4c;\n  font-size: 14px;\n  display: inline-block;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/frontend-common/core-components/ValidatedRadioFields/ValidatedRadioFields.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,qBAAA;EACA,eAAA;EACA,cAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAEJ","sourcesContent":[".question {\n  font-size: 18px;\n  color: #4c4c4c;\n  margin-bottom: 28px;\n  display: flex;\n}\n\n.option {\n  margin-right: 24px;\n  background-color: #fff;\n  padding: 0 12px 0 4px;\n  font-size: 14px;\n  color: #4c4c4c;\n  label {\n    color: #4c4c4c;\n    font-size: 14px;\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": "QQY0qP3GUOmu3vytYDqo",
	"option": "SgnAk2auFDKi05P8P1jp"
};
export default ___CSS_LOADER_EXPORT___;
