function isLocallyServed() {
  return window.location.hostname.includes('local') || window.location.hostname.includes('ngrok');
}

function isDev2() {
  return (
    window.location.hostname.includes('dev-2.in') ||
    window.location.hostname.includes('dev-2-admin')
  );
}

function isDev3() {
  return (
    window.location.hostname.includes('dev-3.in') ||
    window.location.hostname.includes('dev-3-admin')
  );
}

function isAcceptance() {
  return (
    window.location.hostname.includes('acceptance.in') ||
    window.location.hostname.includes('acceptance-admin')
  );
}

function isAcceptance2() {
  return (
    window.location.hostname.includes('acceptance-2.in') ||
    window.location.hostname.includes('acceptance-2-admin')
  );
}

function isProduction() {
  return window.location.hostname === 'admin.in.springverify.com';
}

function getEnv() {
  return isLocallyServed()
    ? 'local'
    : isAcceptance()
    ? 'accept'
    : isAcceptance2()
    ? 'accept-2'
    : isProduction()
    ? 'prod'
    : isDev2()
    ? 'dev-2'
    : isDev3()
    ? 'dev-3'
    : 'dev';
}

const AppHelper = {
  isLocallyServed,
  isDev2,
  isDev3,
  isAcceptance,
  isAcceptance2,
  isProduction,
  getEnv
};

export default AppHelper;
