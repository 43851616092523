// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iCeQGOw2bqRNl_1enhpl {\n  border: 1px solid #ebedf2;\n  border-radius: 4px;\n  background-color: var(--white);\n  font-size: var(--para-font-size);\n  font-weight: normal;\n  line-height: 45px;\n  height: 38px;\n  width: 100%;\n  color: #4c4c4c;\n}\n.iCeQGOw2bqRNl_1enhpl::placeholder {\n  color: #c2cfff;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/frontend-common/core-components/Input/Input.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,8BAAA;EACA,gCAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AACF;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".input {\n  border: 1px solid #ebedf2;\n  border-radius: 4px;\n  background-color: var(--white);\n  font-size: var(--para-font-size);\n  font-weight: normal;\n  line-height: 45px;\n  height: 38px;\n  width: 100%;\n  color: #4c4c4c;\n  &::placeholder {\n    color: #c2cfff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "iCeQGOw2bqRNl_1enhpl"
};
export default ___CSS_LOADER_EXPORT___;
