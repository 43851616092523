// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zwVh6cbi1IRO1T_qqx2H {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-right: 1.5rem;\n}\n.zwVh6cbi1IRO1T_qqx2H .jUTllRnuGM5c6Z_si8gq {\n  display: flex;\n}\n.zwVh6cbi1IRO1T_qqx2H .CQpsf6NUZ5fmzq3UMtx3 {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  font-family: Poppins, sans-serif;\n  color: #333333;\n  cursor: pointer;\n  padding: 24px 16px 24px 16px;\n}\n.zwVh6cbi1IRO1T_qqx2H .CQpsf6NUZ5fmzq3UMtx3:first-child {\n  padding-left: 24px;\n}\n.zwVh6cbi1IRO1T_qqx2H .CQpsf6NUZ5fmzq3UMtx3 .deT0aDbhufzlrfUxC4YX {\n  color: #2755fe;\n  border-bottom: 3px solid #2755fe;\n  padding-bottom: 6px;\n}\n\n.XvyrVmI2FZGYe5XF6dC4 {\n  background: white;\n  border: 1px solid #2755fe;\n  color: #2755fe;\n  margin-left: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/SuperAdmin/frontend-common/core-components/TabComponent/TabComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,qBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,4BAAA;AAEJ;AAAI;EACE,kBAAA;AAEN;AACI;EACE,cAAA;EACA,gCAAA;EACA,mBAAA;AACN;;AAIA;EACE,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;AADF","sourcesContent":[".tabComponentWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-right: 1.5rem;\n  .tabContainer {\n    display: flex;\n  }\n  .tabStyles {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    font-family: Poppins, sans-serif;\n    color: #333333;\n    cursor: pointer;\n    padding: 24px 16px 24px 16px;\n\n    &:first-child {\n      padding-left: 24px;\n    }\n\n    .tabActive {\n      color: #2755fe;\n      border-bottom: 3px solid #2755fe;\n      padding-bottom: 6px;\n    }\n  }\n}\n\n.alumniBtn {\n  background: white;\n  border: 1px solid #2755fe;\n  color: #2755fe;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabComponentWrapper": "zwVh6cbi1IRO1T_qqx2H",
	"tabContainer": "jUTllRnuGM5c6Z_si8gq",
	"tabStyles": "CQpsf6NUZ5fmzq3UMtx3",
	"tabActive": "deT0aDbhufzlrfUxC4YX",
	"alumniBtn": "XvyrVmI2FZGYe5XF6dC4"
};
export default ___CSS_LOADER_EXPORT___;
